import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 64 64" {...props}>
      <g clipPath="url(#clip0_19_213)">
        <path
          d="M5.625 26.25H23.5984L30.5492 33.2007C30.908 33.5595 31.3875 33.7501 31.8755 33.75C32.117 33.75 32.3606 33.7034 32.5926 33.6073C33.2933 33.317 33.7501 32.6334 33.7501 31.875V26.25H38.1251C41.2267 26.25 43.7501 23.7266 43.7501 20.625V5.625C43.7501 2.52338 41.2267 0 38.1251 0H5.625C2.52338 0 0 2.52338 0 5.625V20.625C0 23.7266 2.52338 26.25 5.625 26.25ZM9.375 7.5H34.375C35.4105 7.5 36.25 8.3395 36.25 9.375C36.25 10.4105 35.4105 11.25 34.375 11.25H9.375C8.3395 11.25 7.5 10.4105 7.5 9.375C7.5 8.3395 8.3395 7.5 9.375 7.5ZM9.375 15H34.375C35.4105 15 36.25 15.8395 36.25 16.875C36.25 17.9105 35.4105 18.75 34.375 18.75H9.375C8.3395 18.75 7.5 17.9105 7.5 16.875C7.5 15.8395 8.3395 15 9.375 15Z"
          fill="#00B250"
        />
        <path
          d="M25.625 42.5H23.5614C23.3177 41.3048 22.845 40.1924 22.1929 39.2088L23.6559 37.7459C24.3881 37.0138 24.3881 35.8265 23.6559 35.0942C22.9236 34.362 21.7365 34.362 21.0042 35.0942L19.5413 36.5573C18.5576 35.905 17.4453 35.4323 16.2501 35.1888V33.125C16.2501 32.0895 15.4106 31.25 14.3751 31.25C13.3396 31.25 12.5001 32.0895 12.5001 33.125V35.1886C11.305 35.4321 10.1926 35.905 9.209 36.5571L7.746 35.0941C7.01388 34.362 5.82662 34.362 5.09438 35.0941C4.36225 35.8262 4.36225 37.0135 5.09438 37.7458L6.55737 39.2086C5.90513 40.1923 5.43237 41.3046 5.18888 42.4999H3.125C2.0895 42.4999 1.25 43.3394 1.25 44.3749C1.25 45.4104 2.0895 46.2499 3.125 46.2499H5.18862C5.43225 47.4451 5.905 48.5575 6.55713 49.5411L5.09412 51.004C4.36187 51.7361 4.36187 52.9234 5.09412 53.6556C5.46037 54.0219 5.94012 54.2049 6.42 54.2049C6.89975 54.2049 7.37975 54.0217 7.74575 53.6556L9.20875 52.1926C10.1924 52.8449 11.3048 53.3176 12.4999 53.5611V55.625C12.4999 56.6605 13.3394 57.5 14.3749 57.5C15.4104 57.5 16.2499 56.6605 16.2499 55.625V53.5614C17.445 53.3179 18.5574 52.845 19.541 52.1929L21.004 53.6559C21.3701 54.022 21.85 54.2051 22.3298 54.2051C22.8095 54.2051 23.2895 54.022 23.6556 53.6559C24.3877 52.9238 24.3877 51.7365 23.6556 51.0042L22.1926 49.5414C22.8449 48.5577 23.3176 47.4454 23.5611 46.2501H25.625C26.6605 46.2501 27.5 45.4106 27.5 44.3751C27.5 43.3396 26.6605 42.5 25.625 42.5ZM14.375 46.25C13.3395 46.25 12.5 45.4105 12.5 44.375C12.5 43.3395 13.3395 42.5 14.375 42.5C15.4105 42.5 16.25 43.3395 16.25 44.375C16.25 45.4105 15.4105 46.25 14.375 46.25Z"
          fill="#00B250"
        />
        <path
          d="M63.0622 49.7536L61.5247 48.8659C61.5976 48.282 61.6344 47.7 61.6344 47.125C61.6344 46.55 61.5976 45.968 61.5247 45.3841L63.0622 44.4964C63.959 43.9786 64.2662 42.8319 63.7485 41.9351L59.9985 35.4399C59.4807 34.5431 58.334 34.2357 57.4372 34.7536L55.8931 35.6451C54.9571 34.9354 53.9512 34.3503 52.8844 33.8951V32.125C52.8844 31.0895 52.0449 30.25 51.0094 30.25H43.5094C42.4739 30.25 41.6344 31.0895 41.6344 32.125V33.895C40.5675 34.3501 39.5616 34.9353 38.6256 35.645L37.0816 34.7535C36.1849 34.2358 35.0381 34.543 34.5204 35.4398L30.7704 41.935C30.2526 42.8318 30.5599 43.9785 31.4566 44.4963L32.9941 45.384C32.9212 45.9679 32.8845 46.5499 32.8845 47.1249C32.8845 47.6999 32.9212 48.2819 32.9941 48.8657L31.4566 49.7535C30.5599 50.2713 30.2526 51.418 30.7704 52.3148L34.5204 58.81C35.0381 59.7069 36.1849 60.0141 37.0817 59.4963L38.6257 58.6048C39.562 59.3146 40.5676 59.8996 41.6345 60.3548V62.125C41.6345 63.1605 42.474 64 43.5095 64H51.0095C52.045 64 52.8845 63.1605 52.8845 62.125V60.3549C53.9514 59.8997 54.957 59.3147 55.8932 58.6049L57.4372 59.4964C58.334 60.0141 59.4807 59.7069 59.9985 58.8101L63.7485 52.3149C64.2662 51.4181 63.959 50.2714 63.0622 49.7536ZM47.2594 52.75C44.1577 52.75 41.6344 50.2266 41.6344 47.125C41.6344 44.0234 44.1577 41.5 47.2594 41.5C50.361 41.5 52.8844 44.0234 52.8844 47.125C52.8844 50.2266 50.361 52.75 47.2594 52.75Z"
          fill="#00B250"
        />
      </g>
      <defs>
        <clipPath id="clip0_19_213">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Icon
