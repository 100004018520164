import axios from 'services/axiosInstance'
import Endpoints from 'constants/endpoints'

export interface User {
  userid: string,
  role: string,
  accessToken: string,
  email: string,
  isPaidForCopyTrade ?: any
  bonusDebt?: any
  username?: string
  fullname?: string
  address?: any
  balanceUsdt?: any
  balanceCpie?: any
  linkRefLeft?: any
  linkRefRight?: any
  identityVerificationImageFront?: any
  identityVerificationImageBack?: any
  avatar?: any
  isKYC?: boolean
  isEnableGoogleAuthenticator?: number
}

export interface UserResult {
  success: boolean | null,
  data: User,
  msgCode: string | null,
  // eslint-disable-next-line @typescript-eslint/ban-types
  errors: object | null
}

// eslint-disable-next-line @typescript-eslint/ban-types
export async function userLogin(formData: object): Promise<any> {
  // eslint-disable-next-line no-useless-catch
  try {
    const response:any = await axios().post(Endpoints.LOGIN, formData)
    // const success = response?.result
    return {
      success: response?.result,
      data: {
        userid: response?.data?.id,
        accessToken: response?.data?.accessToken,
        first_name: response?.data?.first_name,
        last_name: response?.data?.last_name,
        phone: response?.data?.phone,
        invite_code: response?.data?.invite_code,
        wallet_withdraw: response?.data?.wallet_withdraw,
        wallet_delivery: response?.data?.wallet_delivery,
        wallet_reward: response?.data?.wallet_reward,
        role: response?.data.role,
        join_time: response?.data.join_time,
        
      },
      // msgCode: response.msgCode,
      // errors: response.errors
    }
  } catch (error) {
    throw error
  }
}

export async function getDetailUser(params?: any) {
  // eslint-disable-next-line no-return-await
  return await axios().get(Endpoints.GET_USER_DETAIL, {params})
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}

export async function CreateUser(data?: any) {
  // eslint-disable-next-line no-return-await
  return await axios().post(Endpoints.GET_USER_CREATE, data)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}
export async function UpdateUser(data?: any) {
  // eslint-disable-next-line no-return-await
  return await axios().post(Endpoints.GET_USER_UPDATE, data)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}

export async function UpdateBank(data?: any) {
  // eslint-disable-next-line no-return-await
  return await axios().post(Endpoints.GET_USER_BANK, data)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}

export async function sendCodeRegister(data?: any) {
  // eslint-disable-next-line no-return-await
  return await axios().post(Endpoints.GET_USER_SEND_CODE_REGISTER, data)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}


export async function authenticationEmail(data?: any) {
  // eslint-disable-next-line no-return-await
  return await axios().post(Endpoints.GET_USER_VERIFY_EMAIL, data)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}


export async function verifyCodeRegister(data?: any) {
  // eslint-disable-next-line no-return-await
  return await axios().post(Endpoints.GET_USER_VERIFY_CODE_REGISTER, data)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}
// export async function getForgotPassword(params?: any) {
//   // eslint-disable-next-line no-return-await
//   return await axios().get(Endpoints.FORGOT_PASSWORD, {params})
//     .then(response => {
//       // console.log("response", response);
//       return response
//     })
//     // 응답(실패)
//     .catch(error => {
//       if (error.response) {
//         // client received an error response (5xx, 4xx)
//         console.log("error.response", error.response);
//         return error.response
//       } else if (error.request) {
//         console.log("error.request", error.request);
//         return error.request
//         // client never received a response, or request never left
//       } else {
//         console.log("error", error);
//         return error
//         // anything else
//       }
//     })
// }

export async function getForgotPassword(data?: any) {
  // eslint-disable-next-line no-return-await
  return await axios().post(Endpoints.FORGOT_PASSWORD, data)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}

export async function UpdateAddress(data?: any) {
  // eslint-disable-next-line no-return-await
  return await axios().post(Endpoints.GET_USER_UPDATE_ADDRESS, data)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}


export async function ChangePass(data?: any) {
  // eslint-disable-next-line no-return-await
  return await axios().post(Endpoints.CHANGE_PASSWORD, data)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}

export async function GetProfile() {
  // eslint-disable-next-line no-return-await
  return await axios().get(Endpoints.GET_PROFILE)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}

