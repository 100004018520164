/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User, userLogin as logInService, GetProfile } from 'services/User'
import { AppThunk, store } from 'redux/store'
import { setContentPopup } from 'features/popup'

interface UserState {
  userid: string
  msgCode: string | null
  isLoading: boolean
  accessToken: string | null
  // eslint-disable-next-line @typescript-eslint/ban-types
  error: object | null
  role: string | null
  first_name?: any
  last_name?: any
  phone?: any
  invite_code?: string
  wallet_withdraw?: any
  wallet_delivery?: any
  wallet_reward?: any
  join_time?: string
  user_name?: any
  email?: string
  total_level1?: any
  total_level2?: any
  total_revenue_level1?: any
  total_revenue_level2?: any
  total_revenue?: any
  total_revenue_all_level?: any
  withdraw?: any
  position?: any
  rate?: any
  level?: any
  total_child?: any
  wallet_gift?: any
  wallet_accumulation?: any
  account_name?: any
  account_number?: any
  bank_name?: any
  withdraw_money?: any
  withdraw_gift?: any

}
const userInitialState: UserState = {
  userid: '',
  isLoading: false,
  msgCode: '',
  first_name: '',
  accessToken: '',
  error: null,
  role: '',
  last_name: '',
  phone: '',
  invite_code: '',
  wallet_withdraw: 0,
  wallet_delivery: 0,
  wallet_reward: 0,
  join_time: '',
  user_name: '',
  email: '',
  total_level1: 0,
  total_level2: 0,
  total_revenue_level1: 0,
  total_revenue_level2: 0,
  total_revenue: 0,
  total_revenue_all_level: 0,
  withdraw: 0,
  position: '',
  rate: '',
  total_child: 0,
  level: [],
  wallet_gift: 0,
  wallet_accumulation: 0,
  account_name: '',
  account_number: '',
  bank_name: '',
  withdraw_money: 0,
  withdraw_gift: 0,

}

function startLoading(state: UserState) {
  // eslint-disable-next-line no-param-reassign
  state.isLoading = true
}

function loadingFailed(state: UserState | undefined, action: PayloadAction<any>) {
  state.isLoading = false
  state.error = action.payload
  state.accessToken = ''
  state.last_name = ''
  state.first_name = ''
  state.user_name = ''
}

function reset(state: UserState) {
  state.msgCode = null
  state.isLoading = false
}

function resetToken(state: UserState) {
  state.msgCode = null
  state.isLoading = false
  state.accessToken = ''
  state.last_name = ''
  state.first_name = ''
  state.user_name = ''
}

const user = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    startLogIn: startLoading,
    setUser(state, { payload }: PayloadAction<any>) {
      const {
        userid,
        accessToken,
        role,
        first_name,
        last_name,
        phone,
        invite_code,
        wallet_withdraw,
        wallet_delivery,
        wallet_reward,
        join_time,
        user_name,
        email,
        total_level1,
        total_level2,
        total_revenue_level1,
        total_revenue_level2,
        withdraw,
        position,
        rate,
        level,
        total_child,
        total_revenue,
        wallet_gift,
        wallet_accumulation,
      } = payload
      const data = { ...payload }
      Object.assign(state, payload)
      // state.userid = userid
      // state.accessToken = accessToken
      // state.isLoading = false
      // state.role = role
      // state.first_name = first_name
      // state.last_name = last_name
      // state.phone = phone
      // state.invite_code = invite_code
      // state.wallet_withdraw = wallet_withdraw
      // state.wallet_delivery = wallet_delivery
      // state.wallet_reward = wallet_reward
      // state.join_time = join_time
      // state.user_name = user_name
      // state.email = email
      // state.total_level1 = total_level1
      // state.total_level2 = total_level2
      // state.total_revenue_level1 = total_revenue_level1
      // state.total_revenue_level2 = total_revenue_level2
      // state.withdraw = withdraw
      // state.position = position
      // state.rate = rate
      // state.level = level
      // state.total_child = total_child
      // state.total_revenue = total_revenue
      // state.wallet_gift = wallet_gift
      // state.wallet_accumulation = wallet_accumulation
    },
    logInFailure: loadingFailed,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    logOut: (_state) => resetToken(_state),
    resetState: reset,
    resetStateToken: resetToken,
  },
})

export const { setUser, startLogIn, logInFailure, logOut, resetState, resetStateToken } = user.actions

export const login =
  // eslint-disable-next-line @typescript-eslint/ban-types
    (formData: object): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(startLogIn())
        const response: any = await logInService(formData)
        if (!response?.result) {
          dispatch(setContentPopup(response?.message))
        }

        console.log('response123:::', response)

        dispatch(setUser(response.data))
      } catch (error: any) {
        dispatch(setContentPopup(error?.message))

        dispatch(logInFailure(error))
      }
    }

export const fetchProfile =
  // eslint-disable-next-line @typescript-eslint/ban-types
  (): AppThunk => async (dispatch) => {
    const { accessToken } = store.getState().user
    if (!accessToken) dispatch(logOut())
    try {
      const response = await GetProfile()
      console.log('GetProfile:::', response)
      if (response?.result) {
        dispatch(
          setUser({
            ...response.data,
            accessToken,
            // role: response?.data.userRole
          }),
        )
      }
    } catch (error) {
      dispatch(logInFailure(error))
    }
  }

export default user.reducer
