import styled from "styled-components";
import { Modal } from "antd";

export const ComponentWp: any = styled(Modal) <{ path: string; width?: string }>`
  &.popup-container {
    max-width: ${(props) => (props.width ? `${props.width}px` : "360px")};
    @media (max-width: 767px) {
      max-width: 324px;
    }
    
    .ant-modal-content {
      min-height: 208px;
      display: flex;
      flex-direction: column;
      object-fit: contain;
      border-radius: 10px;
      box-shadow: 5px 8.7px 27px 3px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      padding: 0;
      .ant-modal-close {
        top: 16px;
        .ant-modal-close-x {
          .ant-modal-close-icon {
            color: #04111d;
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
      .ant-modal-header {
        background: transparent;
        padding: 16px 20px;
        border-bottom: 1px solid transparent;
        .ant-modal-title {
          font-family: 'WorkSans-Bold';
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #04111d;
        }
      }
      .ant-modal-body {
        padding: ${(props) => (props.path ? "0 20px 20px 20px" : "20px")};
        flex: 1;
        font-family: 'WorkSans-Medium';
        font-style: normal;
        font-size: 16px;
        line-height: 19px;
      }
      .ant-modal-footer {
        border-top: 1px solid transparent;
        padding: 10px 20px 20px;
        .ant-btn {
          height: 36px;
          padding: 0px 20px;
          min-width: 110px;
          object-fit: contain;
          border-radius: 18px;
          background-color: #ebecf0;
          font-family: 'WorkSans-Regular';
          border: none;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #707a83;
          &.ant-btn-primary {
            background-color: #00B250;
            color: #fff;
          }
        }
      }
    }

    &.gray{
    .ant-modal-content {
      background: #666873;
    }
  }

    &.not-cancel {
      .ant-modal-footer {
        .ant-btn-default {
          display: none;
        }
      }
    }

    &.not-close-btn {
      .ant-modal-close {
        display: none;
      }
    }

    @media screen and (max-width: 380px) {
      max-width: calc(100% - 36px);
    }
    &.ok-cancel {
      .ant-modal-footer {
        display: none;
        .ant-btn-primary {
          display: none;
        }
      }
    };

    &.hidden {
      visibility: hidden;
    }
  }
  
`;
