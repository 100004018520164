import styled from 'styled-components'
import { Modal } from 'antd'

export const ComponentWp: any = styled(Modal)<{ path: string; width?: string }>`
  &.popup-container {
    max-width: ${(props) => (props.width ? `${props.width}px` : '360px')};
    @media (max-width: 767px) {
      max-width: 324px;
    }
    .ant-modal-content {
      display: flex;
      flex-direction: column;
      object-fit: contain;
      border-radius: 10px;
      box-shadow: 5px 8.7px 27px 3px rgba(0, 0, 0, 0.1);
      background-color: rgba(0, 0, 0, 0.7);
      align-items: center;

      .ant-modal-body {
        text-align: center;
        flex: 1;
        color: #fff;
        font-style: normal;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
`

export const StyleIcon = styled.div`
  height: 60px;
  width: 60px;
  background-color: #5bbf8f;
  border-radius: 50%;
  margin: auto;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`
