import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 64 64" {...props}>
      <g clipPath="url(#clip0_19_177)">
        <path
          d="M41.3103 33.875C40.4391 38.1489 36.527 41.375 32 41.375C27.473 41.375 23.6859 38.1489 22.8148 33.875H19.0645C19.9841 40.2188 25.4056 45.125 32 45.125C38.5944 45.125 44.1409 40.2188 45.0605 33.875H41.3103Z"
          fill="#00B250"
        />
        <path
          d="M48.8105 33.875C47.8639 42.2894 40.6634 48.875 32 48.875C23.3366 48.875 16.2611 42.2894 15.3145 33.875H0V35.75C0 36.5575 0.51825 37.2753 1.28363 37.5298L8.99837 40.0475C9.3225 40.9668 9.66488 41.8035 10.0311 42.5761L6.4715 49.7282C6.11263 50.4496 6.25537 51.3194 6.82487 51.8889L12.1111 57.1751C12.6842 57.7483 13.5521 57.8837 14.2718 57.5285L21.4239 53.9689C22.1966 54.3351 23.0334 54.6775 23.9525 55.0016L26.4702 62.7164C26.7247 63.4817 27.4425 64 28.25 64H35.875C36.6825 64 37.4002 63.4817 37.6548 62.7164L40.1725 55.0016C41.0918 54.6775 41.9285 54.3351 42.7011 53.9689L49.8532 57.5285C50.5729 57.8855 51.4426 57.7483 52.0139 57.1751L57.3001 51.8889C57.8696 51.3194 58.0124 50.4496 57.6535 49.7282L54.0939 42.5761C54.4601 41.8034 54.8025 40.9666 55.1266 40.0475L62.7164 37.5298C63.4817 37.2753 64 36.5575 64 35.75V33.875H48.8105Z"
          fill="#00B250"
        />
        <path
          d="M26.7204 33.875C27.497 36.0528 29.5588 37.625 32 37.625C34.4413 37.625 36.628 36.0528 37.4046 33.875H26.7204Z"
          fill="#00B250"
        />
        <path
          d="M32 0C27.8636 0 24.5 3.36363 24.5 7.5C24.5 11.6364 27.8636 15 32 15C36.1364 15 39.625 11.6364 39.625 7.5C39.625 3.36363 36.1364 0 32 0Z"
          fill="#00B250"
        />
        <path
          d="M50.875 0C46.7386 0 43.375 3.36363 43.375 7.5C43.375 11.6364 46.7386 15 50.875 15C55.0114 15 58.375 11.6364 58.375 7.5C58.375 3.36363 55.0114 0 50.875 0Z"
          fill="#00B250"
        />
        <path
          d="M13.25 0C9.11363 0 5.75 3.36363 5.75 7.5C5.75 11.6364 9.11363 15 13.25 15C17.3864 15 20.75 11.6364 20.75 7.5C20.75 3.36363 17.3864 0 13.25 0Z"
          fill="#00B250"
        />
        <path
          d="M57.2706 16.7354C55.452 17.999 53.2526 18.75 50.875 18.75C48.5072 18.75 46.3147 18.007 44.5004 16.753C43.678 17.2251 42.8876 17.756 42.172 18.3961C45.8184 21.1219 48.35 25.3789 48.8855 30.125H64V28.25C64 23.3471 61.2649 18.9885 57.2706 16.7354Z"
          fill="#00B250"
        />
        <path
          d="M32 18.75C25.399 18.75 19.9205 23.7731 19.0086 30.125H45.1162C44.2045 23.7731 38.601 18.75 32 18.75Z"
          fill="#00B250"
        />
        <path
          d="M19.6246 16.753C17.8102 18.007 15.6178 18.75 13.25 18.75C10.8724 18.75 8.673 17.999 6.85437 16.7354C2.86012 18.9885 0 23.3471 0 28.25V30.125H15.2395C15.775 25.3789 18.3066 21.1219 21.953 18.3961C21.2372 17.756 20.447 17.2251 19.6246 16.753Z"
          fill="#00B250"
        />
      </g>
      <defs>
        <clipPath id="clip0_19_177">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Icon
