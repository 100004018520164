import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 64 64" {...props}>
      <path
        d="M30.125 43.8288V64.0001H6.99625C5.85875 64.0001 4.98375 62.9951 5.13875 61.8688L6.345 53.1363C6.71375 50.4726 7.66 48.0151 9.0475 45.8838C11.1937 42.5863 15.3487 39.5101 19.4775 37.9926C22.0737 41.1638 25.8475 43.3338 30.125 43.8288Z"
        fill="#00B250"
      />
      <path
        d="M15.8187 13.1312V26.62H14.22C12.25 26.62 10.6512 25.0225 10.6512 23.0512V16.7012C10.6512 14.73 12.25 13.1312 14.22 13.1312H15.8187Z"
        fill="#00B250"
      />
      <path
        d="M58.8613 61.8688L57.655 53.1363C57.2862 50.4726 56.34 48.0151 54.9525 45.8838C52.8062 42.5863 48.6512 39.5101 44.5225 37.9926C41.9262 41.1638 38.1525 43.3338 33.875 43.8288V64.0001H57.0037C58.1413 64.0001 59.0163 62.9951 58.8613 61.8688ZM46.8326 49.8879H42.5373C41.5018 49.8879 40.6623 49.0484 40.6623 48.0129C40.6623 46.9774 41.5018 46.1379 42.5373 46.1379H46.8326C47.8681 46.1379 48.7076 46.9774 48.7076 48.0129C48.7076 49.0484 47.8683 49.8879 46.8326 49.8879Z"
        fill="#00B250"
      />
      <path
        d="M53.3488 16.7012V30.1425C53.3488 33.2762 50.7988 35.8275 47.6638 35.8275H41.4463C42.39 34.7262 43.1425 33.46 43.6563 32.0775H47.6638C48.73 32.0775 49.5988 31.2087 49.5988 30.1425V26.62H48.1763V13.1312H49.78C51.75 13.1312 53.3488 14.73 53.3488 16.7012Z"
        fill="#00B250"
      />
      <path
        d="M44.4313 19.6238V27.7563C44.4313 29.275 44.1575 30.73 43.6563 32.0775H33.875C32.8464 32.0775 32 32.9239 32 33.9525C32 34.9812 32.8464 35.8275 33.875 35.8275H41.4463C39.1663 38.4938 35.7775 40.1875 32 40.1875C25.145 40.1875 19.5688 34.61 19.5688 27.7563V19.6238C21.505 21.3225 24.0413 22.355 26.8138 22.355H37.1863C39.9588 22.355 42.495 21.3225 44.4313 19.6238Z"
        fill="#00B250"
      />
      <path
        d="M44.4313 9.38375V9.485H19.5688V9.38375C19.5688 4.21 23.7788 0 28.9525 0H35.0475C40.2213 0 44.4313 4.21 44.4313 9.38375Z"
        fill="#00B250"
      />
      <path
        d="M44.185 13.235C43.3575 16.3237 40.5338 18.605 37.1863 18.605H26.8138C23.4663 18.605 20.6425 16.3237 19.815 13.235H44.185Z"
        fill="#00B250"
      />
    </Svg>
  )
}

export default Icon
