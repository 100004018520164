import { setContentPopup, setPopupSuccess } from 'features/popup'
import React, { useState } from 'react' //lazy
import { useDispatch, useSelector } from 'react-redux'
import { CheckOutlined } from '@ant-design/icons'
import * as S from './style'

interface Props {
  visible?: boolean
  children?: any
  onCancel?: any
  onOk?: any
  title?: any
  footer?: boolean
  okText?: string
  cancelText?: string
  cancel?: boolean
  okCancel?: boolean
  isCloseBtn?: boolean
  className?: any
  path?: string
  width?: string
}

function ModalNotice(): React.ReactElement {
  const dispatch = useDispatch()
  const popup: any = useSelector((state: any) => state.popup?.popupSuccess)

  return (
    <S.ComponentWp
      open={!!popup}
      className={` popup-container`}
      onOk={() => dispatch(setPopupSuccess(''))}
      onCancel={() => dispatch(setPopupSuccess(''))}
      footer={false}
      closeIcon={false}
    >
      <S.StyleIcon>
        <CheckOutlined rev={undefined} />
      </S.StyleIcon>
      <p>{popup || 'Thêm vào giỏ hàng thành công'}</p>
    </S.ComponentWp>
  )
}

export default ModalNotice
