import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import ModalProvider from 'components/Modal/ModalContext'
import { store, persistor } from 'redux/store'
import { ConfigProvider } from 'antd'

const Providers: React.FC = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <ConfigProvider>
            <ModalProvider>{children}</ModalProvider>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  )
}

export default Providers
