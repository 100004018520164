import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 64 64" {...props}>
      <path
        d="M53.4941 17.2308C55.5333 17.2308 57.1864 15.5777 57.1864 13.5385C57.1864 11.4993 55.5333 9.84619 53.4941 9.84619C51.4549 9.84619 49.8018 11.4993 49.8018 13.5385C49.8018 15.5777 51.4549 17.2308 53.4941 17.2308Z"
        fill="#00B250"
      />
      <path
        d="M43.648 9.84616C45.6872 9.84616 47.3403 8.19306 47.3403 6.15386C47.3403 4.11465 45.6872 2.46155 43.648 2.46155C41.6088 2.46155 39.9557 4.11465 39.9557 6.15386C39.9557 8.19306 41.6088 9.84616 43.648 9.84616Z"
        fill="#00B250"
      />
      <path
        d="M33.8018 7.38462C35.841 7.38462 37.4941 5.73151 37.4941 3.69231C37.4941 1.6531 35.841 0 33.8018 0C31.7626 0 30.1095 1.6531 30.1095 3.69231C30.1095 5.73151 31.7626 7.38462 33.8018 7.38462Z"
        fill="#00B250"
      />
      <path
        d="M23.9557 12.3077C25.9949 12.3077 27.648 10.6546 27.648 8.6154C27.648 6.5762 25.9949 4.9231 23.9557 4.9231C21.9165 4.9231 20.2634 6.5762 20.2634 8.6154C20.2634 10.6546 21.9165 12.3077 23.9557 12.3077Z"
        fill="#00B250"
      />
      <path
        d="M49.8018 13.5384V29.5384C49.8018 30.2178 49.2504 30.7692 48.5711 30.7692C47.8917 30.7692 47.3403 30.2178 47.3403 29.5384V6.1538H39.9557V27.0769C39.9557 27.7563 39.4043 28.3076 38.7249 28.3076C38.0455 28.3076 37.4941 27.7563 37.4941 27.0769V3.69226H30.1095V27.0769C30.1095 27.7563 29.5581 28.3076 28.8788 28.3076C28.1994 28.3076 27.648 27.7563 27.648 27.0769V8.61534H20.2634V39.3846L15.2788 32.5636C13.8018 30.2867 10.9194 29.5236 8.80737 30.8283C6.70275 32.1624 6.18091 35.072 7.63814 37.3415C7.63814 37.3415 15.6775 49.5089 19.104 54.7175C22.5304 59.9261 28.0812 64 38.4615 64C55.648 64 57.1864 50.7273 57.1864 46.7692C57.1864 42.811 57.1864 13.5384 57.1864 13.5384H49.8018Z"
        fill="#00B250"
      />
      <path
        d="M31.4154 53C30.4884 53 29.6587 52.8314 28.9264 52.4942C28.1941 52.1525 27.6124 51.685 27.1813 51.0915C26.7549 50.4981 26.5278 49.8192 26.5 49.0549H29.0029C29.0492 49.6214 29.3019 50.0845 29.7607 50.4441C30.2196 50.7993 30.7711 50.9769 31.4154 50.9769C31.9206 50.9769 32.3702 50.8645 32.7642 50.6397C33.1582 50.4149 33.4687 50.1024 33.6958 49.7023C33.9229 49.3022 34.0342 48.8459 34.0295 48.3333C34.0342 47.8118 33.9206 47.3487 33.6889 46.9441C33.4571 46.5395 33.1396 46.2225 32.7364 45.9933C32.3331 45.7595 31.8696 45.6426 31.3459 45.6426C30.9195 45.6381 30.5 45.7145 30.0875 45.8719C29.675 46.0292 29.3482 46.236 29.1072 46.4923L26.7781 46.1214L27.522 39H35.7816V41.0906H29.6564L29.2462 44.7524H29.3297C29.5939 44.4512 29.967 44.2017 30.449 44.0039C30.9311 43.8015 31.4594 43.7004 32.0342 43.7004C32.8963 43.7004 33.6657 43.8982 34.3424 44.2938C35.0191 44.685 35.5521 45.2245 35.9415 45.9123C36.3308 46.6002 36.5255 47.387 36.5255 48.2726C36.5255 49.1853 36.3076 49.999 35.872 50.7139C35.4409 51.4242 34.8407 51.9839 34.0713 52.3931C33.3065 52.7977 32.4212 53 31.4154 53Z"
        fill="white"
      />
      <path
        d="M38.9936 52.8112V39H41.573V45.3459H41.7468L47.3019 39H50.4513L44.945 45.1975L50.5 52.8112H47.3992L43.1512 46.8902L41.573 48.6975V52.8112H38.9936Z"
        fill="white"
      />
    </Svg>
  )
}

export default Icon
