/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../../redux/store'

interface T {
  id: any
  quantity: number
}

interface PopupState {
  listItemCart: Array<T>
  idCart: number
  dataInfoCart: any
}

const popupInitialState: PopupState = {
  listItemCart: [],
  idCart: null,
  dataInfoCart: {}
}

const cart = createSlice({
  name: 'Cart',
  initialState: popupInitialState,
  reducers: {
    setItemCart(state, { payload }: PayloadAction<any>) {
      const {id,quantity} = payload
      const data: any = [...state.listItemCart]
      const dataFind = data?.findIndex(v => v?.id === id)
      if(dataFind>-1){
        data[dataFind] = {
          quantity: data[dataFind]?.quantity + quantity,
          id,
        }
        
      } else {
        data.push({id,quantity})
      }
      state.listItemCart = data
      // state.listItemCart = [...state.listItemCart, payload]
    },
    setIdCart(state, { payload }: PayloadAction<any>) {
      state.idCart = payload
      // state.listItemCart = [...state.listItemCart, payload]
    },
    setInfo(state, { payload }: PayloadAction<any>) {
      state.dataInfoCart = payload
    },
  },
})

export const { setItemCart,setIdCart,setInfo } = cart.actions

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/ban-types
export const fetchPopup =
  (formData: any): AppThunk =>
  async (dispatch) => {
    // dispatch(setPopup())
  }

export default cart.reducer
