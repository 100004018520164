import { generatePath, matchPath } from 'react-router-dom'
import history from 'routerHistory'


export const ADDRESS_SMART = ''

export const HOME = '/'
export const LOGIN = '/login'
export const FORGOT_PASSWORD = '/forgot-password'
export const REGISTER = '/register'
export const SEND_CODE = '/send-code'
export const AUTHENTICATION = '/xac-thuc-email'
export const USER = '/user'
export const INVESTMENT = '/investment' 
export const COMMUNITY = '/community' 
export const ECOSYSTEM = '/ecosystem' 
export const INTRODUCTION = '/introduction' 
export const SUPPLIER = '/supplier' 
export const NEWS = '/news' 
export const CONTACT = '/contact' 
export const PRODUCT = '/product' 
export const PRODUCT_LIST = '/product-list' 
export const CART = '/cart' 


const hashId = '[0-9A-Fa-f]'
const idSpecificPathRegExp = `${hashId}`

export const ID_SLUG = 'prefixedID'
export const ID_ROUTE = `/:${ID_SLUG}`
export const SUPPLIER_DETAIL = `${SUPPLIER}${ID_ROUTE}`
export const PRODUCT_DETAIL = `${PRODUCT}${ID_ROUTE}`
export const PRODUCT_LIST_ID = `${PRODUCT_LIST}${ID_ROUTE}`
export const NEWS_DETAIL = `${NEWS}${ID_ROUTE}`


export const USER_ROUTES = {
  DASHBOARD: `${USER}/dashboard`,
  INVEST: `${USER}/invest`,
  INVEST_ID: `${USER}/invest${ID_ROUTE}`,
  YOUR_WALLET: `${USER}/your-wallet`,
  STAKING: `${USER}/staking`,
  NETWORK: `${USER}/network`,
  COMMISION: `${USER}/commision`,
  PROFILE: `${USER}/profile`,
  PROFILE_INFORMATION: `${USER}/profile/information`,
  PROFILE_CHANGEPASSWORD: `${USER}/profile/change-password`,
  PROFILE_BANK: `${USER}/profile/bank`,
  PROFILE_KYC: `${USER}/profile/kyc`,
  PROFILE_2FA: `${USER}/profile/2fa`,
  SUPPORT: `${USER}/support`,
  CART: `${USER}/cart`,
  COMMISSION: `${USER}/commissiont`,
  COMMISSION_HISTORY: `${USER}/commission-history`,
  LIST_INVITE: `${USER}/list-invite`,
  WITHDRAWAL: `${USER}/withdrawal`,
}

export const extractPrefixed = (path = history?.location?.pathname, route= ID_ROUTE) => {
  const match =
    matchPath(path, {
      path: route,
    })
    const prefixedSID  = match?.params?.[ID_SLUG] || ''
  return prefixedSID
}


export const navigateToId = (id?: string) => {
  const txRoute = generatePath(PRODUCT_LIST_ID, {
    [ID_SLUG]: id,
  })
  history.push(txRoute)
}

export const linkSever = () => {
  const url  = process.env.REACT_APP_SERVER_RESERVE_URL || window?.location?.origin
  console.log(url)
// return url
}