import React, { lazy, useEffect, Suspense } from 'react'
import { Switch, Route, Redirect, Router } from 'react-router-dom'
// eslint-disable-next-line import/no-unresolved
import Loading from 'components/Loading'

import {
  USER_ROUTES,
  HOME,
  LOGIN,
  FORGOT_PASSWORD,
  REGISTER,
  SEND_CODE,
  INTRODUCTION,
  SUPPLIER,
  CONTACT,
  NEWS,
  SUPPLIER_DETAIL,
  PRODUCT_DETAIL,
  PRODUCT,
  CART,
  NEWS_DETAIL,
  PRODUCT_LIST,
  PRODUCT_LIST_ID,
  AUTHENTICATION,
} from 'routes/routes'
import { wrapInSuspense } from 'utils/wrapInSuspense'
import { useSelector } from 'react-redux'

/** Page */
import NotFound from 'views/NotFound'
import Notice from 'components/Notice'
import ModalNotice from 'components/ModalNotice'

import history from './routerHistory'
import GlobalStyle from './style/Global'

const HomePage = lazy(() => import('views/Home'))
const MainPage = lazy(() => import('views/Main'))
const LoginPage = lazy(() => import('views/LoginPage'))
const UserPage = lazy(() => import('views/User'))

declare global {
  interface Window {
    ethereum?: any
    tronWeb?: any
    tronLink?: any
  }
}

const App: React.FC = () => {
  const errorr = false
  const test = useSelector((state?: any) => state)

  // console.log('store', store.getState())

  return (
    <>
      <Router history={history}>
        <Notice />
        <GlobalStyle />
        <Loading />
        <ModalNotice />
        {errorr ? (
          <Route component={NotFound} />
        ) : (
          <>
            <Switch>
              {/* <>
                <Menu account={account} login={login} logout={logout} errorr={errorr} />
                <Route exact path={HOME}>
                  <HomePage />
                </Route>
                <Footer />
              </> */}
              <Route
                exact
                path={[
                  HOME,
                  INTRODUCTION,
                  SUPPLIER,
                  CONTACT,
                  NEWS,
                  SUPPLIER_DETAIL,
                  PRODUCT,
                  PRODUCT_LIST,
                  PRODUCT_LIST_ID,
                  PRODUCT_DETAIL,
                  CART,
                  NEWS_DETAIL,
                ]}
                render={() => wrapInSuspense(<MainPage />, null)}
              />

              <Route
                path={[LOGIN, FORGOT_PASSWORD, REGISTER, SEND_CODE,AUTHENTICATION]}
                render={() => wrapInSuspense(<LoginPage />, null)}
              />

              <Route
                exact
                path={[
                  USER_ROUTES.DASHBOARD,
                  USER_ROUTES.INVEST,
                  USER_ROUTES.YOUR_WALLET,
                  USER_ROUTES.STAKING,
                  USER_ROUTES.NETWORK,
                  USER_ROUTES.COMMISION,
                  USER_ROUTES.PROFILE,
                  USER_ROUTES.PROFILE_INFORMATION,
                  USER_ROUTES.PROFILE_CHANGEPASSWORD,
                  USER_ROUTES.PROFILE_BANK,
                  USER_ROUTES.WITHDRAWAL,
                  USER_ROUTES.PROFILE_2FA,
                  USER_ROUTES.SUPPORT,
                  USER_ROUTES.CART,
                  USER_ROUTES.COMMISSION,
                  USER_ROUTES.COMMISSION_HISTORY,
                  USER_ROUTES.LIST_INVITE,
                ]}
                render={() => wrapInSuspense(<UserPage />, null)}
              />
            </Switch>
          </>
        )}
      </Router>
    </>
  )
}

export default React.memo(App)
