import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from './reducer'

export const useCartData = () => {
  return useSelector((state: RootState) => {
    return state?.cart
  })
}


export const useGetPopupData = () => {
  return useSelector((state: RootState) => {
    return state.popup
  })
}
