/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../../redux/store'

interface PopupState {
  isPopup: boolean
  type?: string
  popup?: any
  message?: any
  popupSuccess?: any
}

const popupInitialState: PopupState = {
  isPopup: false,
  type: '',
  popup: '',
  message: '',
  popupSuccess: '',
}

const popup = createSlice({
  name: 'Popup',
  initialState: popupInitialState,
  reducers: {
    setPopup(state, { payload }: PayloadAction<any>) {
      const { isPopup, type,message } = payload
      state.isPopup = true
      state.type = type
      state.message = message
    },
    hidePopup(state) {
      state.isPopup = false
      state.type = ''
      state.message = ''
    },
    setContentPopup(state: any, action) {
      state.popup = action.payload
    },
    setPopupSuccess(state: any, action) {
      state.popupSuccess = action.payload
    },
  },
})

export const { setPopup,hidePopup, setContentPopup, setPopupSuccess } = popup.actions

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/ban-types
export const fetchPopup =
  (formData: any): AppThunk =>
  async (dispatch) => {
    // dispatch(setPopup())
  }

export default popup.reducer
