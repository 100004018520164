import { combineReducers, Reducer, AnyAction } from '@reduxjs/toolkit'
import { connectRouter } from 'connected-react-router'
import userReducer from 'features/user/userSlice'
import newUsersReducer from 'features/newUsers/newUsersSlice'
import typeMemberReducer from 'features/typeMember'
import popupReducer from 'features/popup'
import saveLoginReducer from 'features/saveLogin'
import cartReducer from 'features/cart'
import history from 'routerHistory'


const combinedReducer = combineReducers({
  router: connectRouter(history),
  user: userReducer,
  newUsers: newUsersReducer,
  typeMember: typeMemberReducer,
  popup: popupReducer,
  cart: cartReducer,
  saveLogin: saveLoginReducer,
})

export type RootState = ReturnType<typeof combinedReducer>

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'logOut') {
    // eslint-disable-next-line no-param-reassign
    state = {} as RootState
  }
  return combinedReducer(state, action)
}
export default rootReducer
