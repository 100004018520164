/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../../redux/store'

interface PopupState {
  typeMember: string,
}

const popupInitialState: PopupState = {
  typeMember: 'NORMAL',
}



const typeMember = createSlice({
  name: 'TypeMember',
  initialState: popupInitialState,
  reducers: {
    setTypeMember(state, { payload }: PayloadAction<any>) {
      const { 
        typeMember, 
      } = payload
      state.typeMember = typeMember
    },
  }
})

export const {
setTypeMember
} = typeMember.actions

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/ban-types
export const fetchTypeMember = (formData: object): AppThunk => async (dispatch) => {
  // dispatch(setPopup())

}

export default typeMember.reducer
