/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  email: '',
  password: '',
  isSave: false,
}

export const userSaveSlice = createSlice({
  name: 'DataUserLogin',
  initialState,
  reducers: {
    setDataLoginSave: (state, { payload }) => {
      const { email, password } = payload
      console.log('payload :>> ', payload);
      state.email = email
      state.password = password
      state.isSave = true

    },
    setDataLoginReset: (state) => {
      state.email = ''
      state.password = ''
      state.isSave = false
    },
  },
})

// Actions
export const { setDataLoginSave, setDataLoginReset } = userSaveSlice.actions

export default userSaveSlice.reducer
