import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved

// import { GmarketSansTTFBold, GmarketSansTTFLight, GmarketSansTTFMedium } from 'assets/font/font'

// declare module 'styled-components' {
//   /* eslint-disable @typescript-eslint/no-empty-interface */
//   export interface DefaultTheme {}
// }

import Fonts from 'assets/font/font'

const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: 'RussoOne-Regular';
  src: url(${Fonts.RussoOneRegular}) format('truetype');
}
@font-face {
  font-family: 'PTSerif-Italic';
  src: url(${Fonts.PTSerifItalic}) format('truetype');
}
 @font-face {
  font-family: 'WorkSans-Light';
  src: url(${Fonts.WorkSansLight}) format('truetype');
}
@font-face {
  font-family: 'WorkSans-Regular';
  src: url(${Fonts.WorkSansRegular}) format('truetype');
}
@font-face {
  font-family: 'WorkSans-Medium';
  src: url(${Fonts.WorkSansMedium}) format('truetype');
}
@font-face {
  font-family: 'WorkSans-SemiBold';
  src: url(${Fonts.WorkSansSemiBold}) format('truetype');
}

@font-face {
  font-family: 'WorkSans-Bold';
  src: url(${Fonts.WorkSansBold}) format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: url(${Fonts.InterRegular}) format('truetype');
}


  body {
    /* background-color: #0d0415; */
    color: #333;
    flex-grow: 0;
    margin: 0px;
    font-family: 'Inter';

    /* background-color: #f5f9fc; */
    
    img {
      height: auto;
      max-width: 100%;
    }
  }

  *{
    /* font-family: 'OpenSans-Regular'; */
    box-sizing: border-box;
  }

.user-setting{
  .ant-dropdown-menu {
    background: linear-gradient(114.83deg, rgba(255, 255, 255, 0.5) 0.8%, rgba(3, 17, 30, 0) 101.31%);
    border: 1px solid #EF5829;
    filter: drop-shadow(0px 0px 25px rgba(255, 151, 0, 0.15));
    backdrop-filter: blur(20px)
  }
}
`

export default GlobalStyle
