import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Overlay from "components/Overlay/Overlay";
import { useGetPopupData } from "redux/hooks";
import { hidePopup, setPopup } from "features/popup";
import { useAppDispatch } from "redux/store";
import { CheckOutlined, CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { Spin, Button } from "antd";
import clsx from 'clsx';

import { Modal,  } from "../Modal";
import {
  // StyleDialog,
} from "./style";

const AccModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  max-width: 100%;
  background-color: transparent;
  @media screen and (min-width: 320px) and (max-width: 375px) {
    margin: 0px 10px;
  }
`;

const Wrapper = styled.div`
  width: 250px;
  min-height: 250px;
  background-color: #fff;
  z-index: 99;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  gap: 10px;

  .defaultBtn{
    width: 65px;
    height: 65px
  }
  .success{
    background-color: #4caf50;
    &:hover{
    background-color: #388e3c;
    }
  }
  .fail{
    background-color: #f44336;
    &:hover{
    background-color: #d32f2f;
    }
  }
`;
const StyleItem = styled.div`
  position: relative;
`;
const StyleFab = styled.div`
  color: #fff;
  background-color: #3f51b5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  .fabProgress{
    position: absolute;
    top: -6px;
    left: -6px;
    z-index: 1;
    color: #3f51b5;
  }
`;
const Message = styled.div`
  margin: 10px 0;
`;

const antIcon = <LoadingOutlined style={{ fontSize: 77 }} spin rev={undefined} />;

function Loading() {
  const wrapperRef = useRef(null);
  const dispatch = useAppDispatch()

  const hideDialog = () => {
    dispatch(hidePopup())
  };
  
  const {isPopup, type,message} = useGetPopupData()
  
  // useEffect(() => {
  //   if (type !== "" && type !== undefined && isPopup) {
  //     setTimeout(function () {
  //       hideDialog();
  //     }, 10000);
  //   }
  // }, [type, isPopup]);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        hideDialog()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);
  
  const buttonClassname = clsx({
    'defaultBtn': type || !type,
    'success': type === 'success',
    'fail': type === 'fail',
  });

console.log('isPopup', isPopup)
  return (
    <>
     {isPopup &&
        <AccModal >
          <Overlay show />
          <Wrapper ref={wrapperRef}>
            <StyleItem>
            <StyleFab
            className={buttonClassname}
            >
                {
                  type === 'success' &&
                  <>
                  <CheckOutlined rev={undefined} style={{fontSize: 24}} /></>
                }
                {
                  type === 'fail' &&
                  <CloseOutlined rev={undefined} style={{fontSize: 24}} />
                }
  
              {!type && <Spin indicator={antIcon} className="fabProgress" />}
            </StyleFab>
            </StyleItem>
            {
              type === 'fail' &&
              <Message>
                {message}
              </Message>
            }
            {
              type &&  <Button style={{borderRadius: 8}} onClick={hideDialog}>Ok</Button>
            }
  
          </Wrapper>
        </AccModal>

      }
    </>

  );
}

export default Loading;
