const Endpoints = {
  DOWNLOAD: '/report',
  LOGIN: '/api/v1/user/login',
  GET_USER_DETAIL: '/api/v1/public/user/details',
  GET_USER_CREATE: '/api/v1/user/register',
  GET_USER_UPDATE: '/api/v1/user/update',
  GET_USER_BANK: '/api/v1/user/update-bank',

  GET_USER_VERIFY_EMAIL: '/api/v1/user/verify-email',


  GET_USER_SEND_CODE_REGISTER: '/api/v1/public/auth/send-code-register',
  GET_USER_VERIFY_CODE_REGISTER: '/api/v1/public/auth/confirm-code-register',

  GET_USER_UPDATE_ADDRESS: '/api/v1/public/user/update-address',

  CHANGE_PASSWORD: '/api/v1/user/change-password',
  FORGOT_PASSWORD: '/api/v1/user/forgot-password',

  GET_ALL_MEMBER: '/api/v1/admin/list-user',
  GET_MEMBER_DETAIL: '/api/v1/admin/detail-user',
  GET_STATISTIC_USER: '/api/v1/admin/statistic-user',
  GET_USED_POINT: '/api/v1/admin/detail-user/point-used',
  UPDATE_USER_STATUS: '/api/v1/admin/update-user-status',
  CHANGE_COMMENT_STATUS: '/api/v1/comments/update-status',
  CHANGE_CAR_TALK_STATUS: '/api/v1/cartalk/update-status',
  GET_ALL_COMMENTS: '/api/v1/comments',


  GET_ALL_NOTIFICATION: '/api/v1/admin/acarz-notification',
  EDIT_NOTIFICATION: '/api/v1/admin/acarz-notification/update',

  GET_PROFILE: '/api/v1/user/info',

  UPLOAD_IMAGE: '/api/v1/public/upload/single-file',
  GET_ALL_COUNTRY: '/api/v1/public/country/list',

  GET_PRODUCT_HOT: '/api/v1/product/hot',
  GET_PRODUCT_PROMOTION: '/api/v1/product/promotion',
  GET_PRODUCT_BY_BRAND: '/api/v1/product/list-by-brand',
  GET_PRODUCT_LIST: '/api/v1/product/list',
  GET_PRODUCT_DETAIL: '/api/v1/product',
  
  GET_ALL_NEWS: '/api/admin/blog/list',

  GET_BRAND_LIST: '/api/v1/brand/list',
  CREATE_CART: '/api/v1/cart/create',
  ADD_CART: '/api/v1/cart/add-product',
  DELETE_CART: '/api/v1/cart/delete-product',
  DETAIL_CART: '/api/v1/cart/get',
  CREATE_ORDER: '/api/v1/order/create',
  LIST_ORDER: '/api/v1/order/list-by-user',
  DETAIL_ORDER: '/api/v1/order/detail',

  CREATE_CONTACT: '/api/v1/contact/create',
  
  GET_COMMISSION_LIST: '/api/v1/commission/history',
  GET_WITHDRAWAL_LIST: '/api/v1/withdraw/list-by-user',
  CREATE_WITHDRAWAL: '/api/v1/withdraw/create',
  GET_REVENUE_LIST: '/api/v1/user/revenue',

  UPLOADS_IMAGE: '/api/v1/image/upload',
  
}

export default Endpoints