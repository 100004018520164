import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#clip0_222_2888)">
        <path
          d="M17 7L15.59 8.41L18.17 11H8V13H18.17L15.59 15.58L17 17L22 12L17 7ZM4 5H12V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H12V19H4V5Z"
          fill="#FF2828"
        />
      </g>
      <defs>
        <clipPath id="clip0_222_2888">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Icon
