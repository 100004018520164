import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import Loading from 'views/Loading'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import commonEn from 'translations/en/common.json'
import commonVn from 'translations/vn/common.json'
import App from './App'
import Providers from './Providers'

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'vn', // language to use
  resources: {
    vn: {
      common: commonEn,
    },
    en: {
      common: commonVn, // 'common' is our custom namespace
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Providers>
        <Suspense fallback={<Loading />}>
          <App />
        </Suspense>
      </Providers>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
